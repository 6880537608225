import { LoaderContainer } from './index.styled';

const Loader = () => {
  return (
    <LoaderContainer>
      <div />
      <div />
      <div />
      <div />
    </LoaderContainer>
  );
};

export default Loader;
