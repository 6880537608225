import Spin from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from './index.styled';

const ButtonComponent = (props: any) => {
  const { children, isLoading, disabled, onClick } = props;
  const antIcon = <LoadingOutlined spin={true} />;

  const _onClick = (e: any) => {
    if (!disabled && !isLoading && onClick) {
      onClick(e);
    }
  };

  return (
    <Button {...props} onClick={_onClick}>
      {isLoading && <Spin indicator={antIcon} />}
      {!isLoading && children}
    </Button>
  );
};

export default ButtonComponent;
