import { IUser } from 'models/user.models';
import { getTokenFromLocalStorage, parseJwt } from 'utils/token';

export const getParsedToken = () => {
  const token = getTokenFromLocalStorage();
  if (!token) return;

  return parseJwt(token) as IUser;
};

export const getSessionUserId = () => getParsedToken()?.id;
