import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserUpdate) {
    updateUser(input: $input) {
      id
      email
      firstName
      lastName
      imageUrl
      roles
      status
      isAdmin
      language
      timezone
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUser($input: UserInput!) {
    addUser(input: $input) {
      id
    }
  }
`;

export const RESEND_VERIFICATION_LINK = gql`
  mutation ResendVerfification($email: String!) {
    resendVerfification(email: $email) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export const FETCH_ATTENDEE_TOKENS = gql`
  query attendeeTokens($input: TokenInput!) {
    attendeeTokens(input: $input) {
      primarySessionToken {
        token
      }
      secondarySessionToken {
        token
      }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($userId: String, $roles: [UserRole]) {
    updateUserRole(userId: $userId, roles: $roles) {
      id
      roles
    }
  }
`;
