import { InMemoryCacheConfig } from '@apollo/client/cache';

const typePolicies: InMemoryCacheConfig['typePolicies'] = {
  Query: {
    fields: {
      getFolderChildrensById: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  MeetingAttendee: {
    keyFields: ['userId', 'meetingSlug'],
  },
  WebinarAttendee: {
    keyFields: ['userId', 'webinarId'],
  },
  EventAttendee: {
    keyFields: ['userId'],
  },
  EC2RDPResponse: {
    keyFields: ['hostname'],
  },
  MeetingsType: {
    keyFields: ['id', 'startTime'],
  },
  WebinarTypes: {
    keyFields: ['id', 'startTime'],
  },
};

export default typePolicies;
