import { Heading } from 'components/typography';
import { Container, Backdrop } from './index.styled';

const ServerError = () => {
  return (
    <Container>
      <Backdrop>503</Backdrop>
      <Heading level={1}>
        We are upgrading our service,
        <br />
        please refresh in a minute and try again
      </Heading>
    </Container>
  );
};

export default ServerError;
