import styled, { css } from 'styled-components';

export const Container = styled.div<{ fixed: boolean }>`
  ${(props) =>
    props.fixed
      ? css`
          width: 100vw;
          height: 100vh;
          position: fixed;
        `
      : css`
          width: 100%;
          height: 100%;
          position: absolute;
        `};

  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.white};
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    margin-bottom: 20px;
  }
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;

  div {
    position: absolute;
    top: 1px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: load-animation1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: load-animation2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: load-animation2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: load-animation3 0.6s infinite;
  }
  @keyframes load-animation1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes load-animation3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes load-animation2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
