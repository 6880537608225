import { ClientOptions, SubscriptionClient } from 'subscriptions-transport-ws';

import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { EKey } from 'general/helper';

import typePolicies from './typePolicies';

export const CACHE = new InMemoryCache({ typePolicies });

export const CLIENT_ROOT: string =
  process.env.REACT_APP_BACKEND_SITE_URL ||
  'https://korber.work-space.app/graphql';

const defaultOptions: any = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(EKey.TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloLink = createHttpLink({
  uri: CLIENT_ROOT,
});

export const client = new ApolloClient({
  link: authLink.concat(apolloLink),
  cache: CACHE,
});

export const subscriptionClient = (
  uri: string,
  options: ClientOptions = {},
  onDisconnected?: any,
  onReconnected?: any,
) => {
  const websocketUri = `ws${uri.substring(4)}`;
  const client = new SubscriptionClient(websocketUri, {
    ...options,
    minTimeout: 2000,
    timeout: 30000,
  });
  const apolloLink = new WebSocketLink(client);
  onDisconnected && client.onDisconnected(onDisconnected);
  onReconnected && client.onReconnected(onReconnected);
  return new ApolloClient({
    link: apolloLink,
    cache: CACHE,
    defaultOptions,
  });
};
