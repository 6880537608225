import styled from 'styled-components';
import Typography from 'antd/es/typography';

const { Title, Text: AntdText, Link } = Typography;

export { Link };

export const Heading = styled(Title)`
  font-family: ${({ theme }) => theme.fontFamily.bold.fontFamily};
  font-weight: ${({ theme }) => theme.fontFamily.bold.fontWeight} !important;
`;

export const Text = styled(AntdText)`
  font-weight: 300;
  color: ${(props) => props.theme.color.black};
`;

export const Label = styled.label<{ $area?: string }>`
  font-size: 15px;
  ${({ theme }) => {
    return { ...theme.fontFamily.semiBold };
  }};
  color: ${(props) => props.theme.color.black};

  grid-area: ${(props) => props.$area};
`;
export const Muted = styled.span`
  font-size: 15px;
  ${({ theme }) => {
    return { ...theme.fontFamily.regular };
  }};
  color: ${(props) => props.theme.color.darkGray4};
`;
