import { createContext, FC } from 'react';

import { IUser } from 'models/user.models';
import { useSessionUser } from 'hooks/useSessionUser/useSessionUser';

export interface IUsers {
  [key: string]: IUser;
}

export const UserContext = createContext<ReturnType<typeof useSessionUser>>({
  loading: false,
  user: undefined,
  error: undefined,
  refetch: () => new Promise((res, rej) => rej()),
});

export const UserProvider: FC = ({ children }) => {
  const { user, loading, error, refetch } = useSessionUser();

  return (
    <UserContext.Provider
      value={{
        user,
        error,
        loading,
        refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
