import { gql } from '@apollo/client';
import { IUser } from 'models/user.models';

export const FETCH_ATTENDEE_TOKENS = gql`
  query attendeeTokens($input: TokenInput!) {
    attendeeTokens(input: $input) {
      primarySessionToken {
        token
      }
      secondarySessionToken {
        token
      }
    }
  }
`;

export const LOGIN = gql`
  query LOGIN($email: String!, $password: String) {
    login(email: $email, password: $password) {
      id
      email
      firstName
      lastName
      imageUrl
      token
      isAdmin
      status
      roles
    }
  }
`;

export interface IQPublicLogin {
  publicLogin: Pick<
    IUser,
    | 'id'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'imageUrl'
    | 'token'
    | 'isAdmin'
    | 'status'
    | 'roles'
    | 'timezone'
    | 'language'
  > & {
    consent: boolean;
  };
}
export interface IQVPublicLogin {
  id?: string;
}
export const PUBLIC_LOGIN = gql`
  query PUBLIC_LOGIN($id: String) {
    publicLogin(id: $id) {
      id
      token
    }
  }
`;

export const FETCH_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      imageUrl
      isAdmin
      roles
      consent
      timezone
      language
    }
  }
`;

export const FETCH_USER_BY_EMAIL = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      email
      firstName
      lastName
      isAdmin
      roles
      token
      imageUrl
    }
  }
`;

export interface IQUsersByEmails {
  usersByEmails: Pick<
    IUser,
    'id' | 'email' | 'firstName' | 'lastName' | 'isModerator' | 'isAdmin'
  >[];
}
export interface IQVUsersByEmails {
  emails: string[];
}

export const FETCH_USER_BY_EMAILS = gql`
  query UsersByEmails($emails: [String]) {
    usersByEmails(emails: $emails) {
      id
      email
      firstName
      lastName
      isModerator
      isAdmin
    }
  }
`;

export const FETCH_ALL_ADMINS = gql`
  query Admins {
    admins {
      id
      salutation
      firstName
      lastName
      email
      imageUrl
      company
      country
      status
      isAdmin
      isModerator
    }
  }
`;

export const FETCH_ALL_USERS = gql`
  query users(
    $offset: Int!
    $limit: Int!
    $filters: [IQueryFilter]
    $sort: IOrderByFilter
  ) {
    users(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
      meta_data {
        total_count
        offset
        limit
      }
      users {
        id
        salutation
        firstName
        lastName
        email
        imageUrl
        company
        country
        status
        isAdmin
        isModerator
        lastLogin
        roles
        deleted
      }
    }
  }
`;

export const EMAIL_EXISTS = gql`
  query checkIfEmailExists($email: String) {
    checkIfEmailExists(email: $email)
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  query requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  query resetPassword($resetToken: String!, $password: String) {
    resetPassword(resetToken: $resetToken, password: $password)
  }
`;
