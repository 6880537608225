import gql from 'graphql-tag';

export const GET_USER = gql`
  query GET_USER($id: String!) {
    user(id: $id) {
      id
      roles
      email
      isAdmin
      consent
      imageUrl
      lastName
      timezone
      language
      firstName
    }
  }
`;
