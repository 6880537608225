import styled, { css } from 'styled-components';
import darken from 'polished/lib/color/darken';
import readableColor from 'polished/lib/color/readableColor';
import { device } from 'styles/devices';

export const Button = styled.button<any>`
  ${(props) => {
    const { disabled, size, theme } = props;
    const background = props?.background || theme.color?.primary;
    const color = readableColor(background);
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${size === 'small' ? '12px' : '14px'};
      font-weight: bold;
      color: ${color};
      background: ${background};
      border: ${`1px solid ${background}`};
      height: ${size === 'small' ? '30px' : '40px'};
      padding: ${size === 'small' ? '0px 16px' : '0px 32px'};
      border-radius: 50px;
      transition: all 0.25s;
      overflow: hidden;
      max-width: 100%;
      white-space: pre;
      line-height: 1;
      outline: none;
      cursor: pointer;
      svg {
        fill: ${color};
      }
      ${!disabled &&
      css`
        &:hover {
          background: ${darken(0.05, background)};
          border: 1px solid ${darken(0.05, background)};
          color: ${readableColor(darken(0.05, background))};
        }
        &:focus,
        &:active {
          background: ${darken(0.1, background)};
          border: 1px solid ${darken(0.1, background)};
          color: ${readableColor(darken(0.1, background))};
          svg {
            fill: ${color};
          }
        }
      `}
      @media ${device.mobile} {
        width: 100%;
      }
    `;
  }}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: ${(props) => props.theme.color.darkGray2};
      border: 1px solid ${(props) => props.theme.color.darkGray2};
      color: ${props.theme.color.darkGray4};
      svg {
        path {
          fill: ${props.theme.color.darkGray4};
        }
      }
    `}
`;
