import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
`;

export const Backdrop = styled.div`
  position: absolute;
  z-index: -1;
  color: ${(props) => props.theme.color.lightGray};
  font-size: 500px;
  opacity: 0.15;
  font-weight: bold;
`;
