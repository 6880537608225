import queryString from 'query-string';

import message from 'antd/es/message';

import { IExitExtended, IQueryParams } from './browser.types';

export const copyToClipboard = async (text: string, notify: boolean = true) => {
  try {
    const response = await navigator.clipboard.writeText(text);
    if (notify) {
      message.success('Copied to clipboard!');
    }

    return response;
  } catch (ex) {
    if (notify) {
      message.error('Could not copy to clipboard.');
    } else {
      const error = ex as Error;
      throw error;
    }
  }
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  let temp;
  let match = userAgent.match(
    /(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i,
  );
  if (match && (temp = userAgent.match(/version\/([.\d]+)/i)) != null) {
    match[2] = temp[1];
  }
  match = match
    ? [match[1], match[2]]
    : ['Netscape', window.navigator.userAgent, '-?'];
  return match.join(' ');
};

export const detectOS = () => {
  if (navigator.userAgent.indexOf('Windows NT 10.0') !== -1)
    return 'Windows 10';
  if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) return 'Windows 8';
  if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) return 'Windows 7';
  if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
    return 'Windows Vista';
  if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) return 'Windows XP';
  if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1)
    return 'Windows 2000';
  if (navigator.userAgent.indexOf('Mac') !== -1) return 'Mac/iOS';
  if (navigator.userAgent.indexOf('X11') !== -1) return 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
};

export const isSafari = () => {
  return (
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0
  );
};

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.platform);
};

export const exitFullScreen = async () => {
  if (document.exitFullscreen) await document.exitFullscreen();
  try {
    if ('mozCancelFullScreen' in document)
      document.mozCancelFullScreen && (await document.mozCancelFullScreen());
  } catch (ex) {
    console.debug(ex);
  }
  if ('webkitCancelFullScreen' in document)
    document.webkitCancelFullScreen &&
      (await document.webkitCancelFullScreen());
  if ('msExitFullscreen' in document)
    document.msExitFullscreen && (await document.msExitFullscreen());
};

export const requestFullScreen = async () => {
  try {
    const element = document.documentElement as HTMLElement & IExitExtended;
    if (element.requestFullscreen) await element.requestFullscreen();
    try {
      if ('mozRequestFullScreen' in element)
        await element.mozRequestFullScreen();
    } catch (ex) {
      console.debug(ex);
    }
    if ('webkitRequestFullScreen' in element)
      await element.webkitRequestFullScreen();
    if ('msRequestFullscreen' in element) await element.msRequestFullscreen();
  } catch (ex) {
    console.error(ex);
  }
};

export const isFullScreenMode = () =>
  !!(
    document['webkitFullscreenElement'] ||
    document['mozFullScreen'] ||
    document['msFullscreenElement'] ||
    document['fullscreenElement']
  );

export const getParams = (): IQueryParams => {
  const search = window.location.search;
  return queryString.parse(search);
};

export enum EBrowser {
  EDGE = 'Edge',
  OPERA = 'Opera',
  CHROME = 'Chrome',
  SAFARI = 'Safari',
  UNKNOWN = 'Uknown',
  FIREFOX = 'Firefox',
}
export const getBrowser = () => {
  const { userAgent } = navigator;

  switch (true) {
    case !!userAgent.match(/opr\//i):
      return EBrowser.OPERA;
    case !!userAgent.match(/edg/i):
      return EBrowser.EDGE;
    case !!userAgent.match(/firefox|fxios/i):
      return EBrowser.FIREFOX;
    case !!userAgent.match(/chrome|chromium|crios/i):
      return EBrowser.CHROME;
    case !!userAgent.match(/safari/i):
      return EBrowser.SAFARI;
    default:
      return EBrowser.UNKNOWN;
  }
};

export const isURLValid = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (ex) {
    return false;
  }
};
