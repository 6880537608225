import { FC, useContext, useEffect, useState } from 'react';
import { Route, useHistory, Redirect, useLocation } from 'react-router-dom';
import Loader from 'components/loader';

import { UserContext } from 'context/user';
import { OrganizationContext } from 'context/organization.context';
import { logout } from 'utils/token';

import { getParams, parseJwt } from 'general/helper';
import usePublicLogin from 'hooks/usePublicLogin';
import { hasAccess } from 'utils/access/access.utils';
import NoMatch from './noMatch/component';

const PrivateRoute: FC<any> = ({
  component,
  path,
  exact = false,
  admin = false,
  access,
  ...props
}) => {
  const { publicLogin } = usePublicLogin();
  const { push } = useHistory();
  const location = useLocation();
  const { user, loading: userLoading } = useContext(UserContext);
  const org = useContext(OrganizationContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectUrl = `${location?.pathname}${location?.search}`
    .replace(/^\/|\/$/g, '')
    .trim();

  useEffect(() => {
    _fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchUser = async () => {
    const params = getParams();
    const token = params?.token || localStorage.getItem('token') || '';
    if (!token) {
      if (admin) {
        push(`/login?redirect=${redirectUrl}`);
      } else {
        push(`/sign-in?redirect=${redirectUrl}`);
      }
      return;
    }
    const id = parseJwt(token)?.id;
    if (!id) {
      if (admin) {
        push(`/login?redirect=${redirectUrl}`);
      } else {
        push(`/sign-in?redirect=${redirectUrl}`);
      }
    } else {
      try {
        await publicLogin(id);
      } catch (ex) {
        logout();
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading || userLoading) {
    return <Loader />;
  }

  if (!hasAccess(access, org?.organization?.accessAllowed)) {
    return <NoMatch />;
  }

  if ((user?.id && !admin) || (admin && user?.isAdmin)) {
    return <Route {...props} component={component} path={path} exact={exact} />;
  } else if (admin) {
    return <Redirect to={`/login?redirect=${redirectUrl}`} />;
  } else {
    return <Redirect to={`/sign-in?redirect=${redirectUrl}`} />;
  }
};

export default PrivateRoute;
