import { createGlobalStyle } from 'styled-components';
import readableColor from 'polished/lib/color/readableColor';

import 'antd/dist/antd.variable.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { device } from './devices';
import { theme } from 'assets/theme';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    min-height: 100%;
    color: #262523;
    ${{ ...theme.fontFamily.regular }};
    overflow: auto;
  }

  .ant-notification-notice-success {
    background-color: #00800D;
    width: 650px;
  }

  .ant-notification-notice-warning {
    background-color: #CE3800;
    width: 650px;
  }

  .ant-notification-close-icon {
    padding-top: 5px;
    font-size: 20px;
  }

  .OT_root {
    .OT_bar, .OT_audio-level-meter, .OT_edge-bar-item, .OT_video-disabled-indicator {
      display: none !important;
    }
  }
  .ant-modal-mask{
    // backdrop-filter: blur(10px);
  }
  .cheers-popover {
    .ant-popover-inner {
      border-radius: 10px;
      .ant-popover-inner-content {
        padding: 5px;
      }
    }
  }
  .options-popover {
    .ant-popover-arrow {
      display: none;
    }
  }
  .sidemenu-drawer {
    padding: 0px !important;
    .ant-drawer-content {
      @media ${device.mobile}, ${device.tiny} {
        width: 100vw;
        min-height: 100vh;
        background: transparent;
      }
      background: transparent;
      box-shadow: none;
    }
    .ant-drawer-content-wrapper {
      box-shadow: none !important;
      // backdrop-filter: blur(10px);
    
    }
    .ant-drawer-body {
      padding: 0px;
      position: relative;
      
    }
    .ant-drawer-mask {
      @media ${device.mobile}, ${device.tiny} {
        display: none;
    }
    }
  }
  
  .fileUploadModal {
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      padding: 0px !important;
    }
  }

  .sidesearchmenu-drawer {
    padding: 0px !important;
    .ant-drawer-content {
      background: transparent;
      box-shadow: none;
    }
    .ant-drawer-content-wrapper {
      box-shadow: none !important;
      // backdrop-filter: blur(10px);
    }
    .ant-drawer-body {
      display: block;
      padding: 0px;
      position: relative;
    }
    .ant-drawer-mask { 
      @media ${device.mobile}, ${device.tiny} {
        display: none;
      }
    }
  }
  .conference-popover {
    margin-right: 100px;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      background: rgba(0,0,0,0.8);
      // backdrop-filter: blur(10px);
    }
  }
  .ant-picker-time-panel-column::after {
    height: unset !important;
  }
  .appointement-modal {
    padding: 0px;
    overflow-x: hidden;
    .ant-modal {
      max-width: unset !important;
      top: 0 !important;
      min-width: 100vw;
      min-height: 100vh;
      padding: 0;
      margin: 0px;
    }
    .ant-modal-content {
      width: 100vw;
      .ant-modal-close{
        right:1rem;
      }
      .ant-modal-close-x {
        background-color: ${theme.color.primary} !important;
        color: ${readableColor(theme.color.primary)} !important;
      }
      @media ${device.mobile}, ${device.tiny} {
        width: 100vw;
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .setting-dropdown {
    z-index: 1220;
  }
  .combobox-admin {
    .ant-tag-close-icon {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .ant-select-item-option-selected {
      background-color: ${theme.color.primary} !important;
      color: ${readableColor(theme.color.primary)} !important;
      path {
        fill: ${readableColor(theme.color.primary)} !important;
      }
    }
  }
  
  .media-sharing-stream-tour-modal {
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-content {
      background-color: transparent !important;
      box-shadow: none;
    }
    .ant-modal-body {
      display: flex;
      justify-content: center;
    }
  }

  .past-events-filter-range {
    & > * {
      font-family: ${theme.fontFamily.regular.fontFamily} !important;
    }
    .ant-picker-date-panel, .ant-picker-header {
      background-color: #FAFAFC !important;
    }

    .ant-picker-cell-range-start > .ant-picker-cell-inner,
    .ant-picker-cell-range-end > .ant-picker-cell-inner {
      border-radius: 50% !important;
      background-color: ${theme.color.primary} !important;
      color: ${readableColor(theme.color.primary)};
    }
  }

  .email-share-modal {
    .ant-modal-body {
      padding: 0px;
    } 
  }

  .privacy-policy-modal {
    .ant-modal-body {
      padding: 0px;
    } 
  }
  .roles-dropdown {
    .ant-select-item-option-content {
      text-transform: capitalize;
    }
  }
  .video-js .vjs-text-track-display > div > div > div { 
    font-size: 24px !important; 
    line-height: 1.2;
    padding: 5px !important;
    background: rgba(0,0,0,0.5) !important;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    border-radius: 5px;
    font-family: ${theme.fontFamily.regular.fontFamily} !important;
    @media ${device.mobile} {
      font-size: 14px !important; 
      line-height: 1.2;
    }
  }
  .ant-divider-horizontal {
    min-width: unset !important;
  }
  .ant-tag-close-icon {
    path {
      fill: #aaaaaa;
    }
  }
  .ant-picker-dropdown, .ant-select-dropdown {
    position: fixed !important;
  }

  .levelswitcher{
    .ant-modal-body {
      padding: 40px !important;
    }
  }
  .detect-device-spinner {
    background: rgba(245, 245, 245 , .8);
    .ant-spin {
      backdrop-filter: blur(3px);
    }
    .ant-spin-text {
      color: black;
    }
  }

  .minimap_moodal_switcher {
    .ant-modal-content {
      background-color: transparent !important;
      box-shadow: none !important;

      form {
        padding: 30px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }

    .ant-modal-close {
      display: none !important;
    }
  }

  .minimap_moodal_switcher_mobile {
    .ant-modal {
      height: 100vh !important;
      width: 100vw !important;
    }
    .ant-modal-content {
      /* background-color: transparent !important; */
      height: 100vh;
      box-shadow: none !important;

      form {
        padding: 30px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }

    .ant-modal-body {
      height: 100% !important;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-wrap {
      z-index: 3000 !important;
    }
  }
`;

export default GlobalStyle;
