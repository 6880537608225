import { FC, memo, useEffect, useState } from 'react';
import * as OTClient from '@opentok/client';
import Loader from 'components/loader';
import NetworkTest, { NetworkTestOptions } from 'opentok-network-test-js';
import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import Message from 'antd/es/message';
import useTestHook from './useNetworkTest';
import { Wrapper } from './index.styled';

type ICallback = (
  isError: boolean,
  isSuccessfull: boolean,
  isAudioAvailable: boolean,
  isVideoAvailable: boolean,
  results: QualityTestResults | null,
  error: any | null,
) => void;

interface IConnectivityTestProps {
  apiKey?: string;
  sessionId?: string;
  token?: string;
  callback?: ICallback;
  disableAudioVideoTest: boolean;
}

const mapper = {
  idle: 'Initializing Koerber Connection Test',
  testing: 'Testing audio and video source ...',
  resolved: 'Koerber connectivity test completed!',
  rejected: 'Koerber connection test failed!',
  pending: 'Connecting Koerber server',
};

const ConnectivityTest: FC<IConnectivityTestProps> = memo(
  ({
    apiKey = process.env.REACT_APP_VONAGE_API_KEY,
    sessionId = '2_MX40NzAwMDY5NH5-MTYyMjYzMzg1NDM1OX5YaTNzWmRPNVBGdFZ6aTVZUWxZUDNVamV-fg',
    token = 'T1==cGFydG5lcl9pZD00NzAwMDY5NCZzaWc9NDc0ZmRiOTQ0MzYyODAyOGRlZmU1YTczOWRjNmVkODZiMWQzNTZiMDpzZXNzaW9uX2lkPTJfTVg0ME56QXdNRFk1Tkg1LU1UWXlNall6TXpnMU5ETTFPWDVZYVROeldtUlBOVkJHZEZaNmFUVlpVV3haVUROVmFtVi1mZyZjcmVhdGVfdGltZT0xNjIzMTQzMTcyJm5vbmNlPTAuMjg3OTI4OTg1NDg4OTQ0NCZyb2xlPW1vZGVyYXRvciZleHBpcmVfdGltZT0xNjIzNzQ3OTcyJmNvbm5lY3Rpb25fZGF0YT10bSU0MGVtYnJhY2UtaXQuY29tJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9Zm9jdXM=',
    callback,
    disableAudioVideoTest = false,
  }) => {
    const [otNetworkTest] = useState(
      // @ts-ignore
      new NetworkTest(OTClient, {
        apiKey,
        sessionId,
        token,
        optiions: { timeout: 5000 } as NetworkTestOptions,
      }),
    );
    const state = useTestHook(otNetworkTest, disableAudioVideoTest);
    const {
      isError,
      isAudioAvailable,
      isVideoAvailable,
      error,
      isSuccessfull,
      qualityTestResults,
      state: _state,
    } = state;

    useEffect(() => {
      if (isError) {
        Message.error('Koerber Connection Test Failed!');
      } else if (isSuccessfull) {
        Message.success('Koerber Connection Test Completed Successfully!');
      }

      if (isSuccessfull || isError)
        callback &&
          callback(
            isError,
            isSuccessfull,
            isAudioAvailable,
            isVideoAvailable,
            qualityTestResults,
            error,
          );
    }, [
      isError,
      callback,
      isAudioAvailable,
      isVideoAvailable,
      isSuccessfull,
      qualityTestResults,
      error,
    ]);

    if (isError || isSuccessfull) return null;

    return (
      <Wrapper>
        {_state !== 'rejected' && _state !== 'resolved' && (
          <Loader label={mapper[_state]} />
        )}
      </Wrapper>
    );
  },
);

export default ConnectivityTest;
