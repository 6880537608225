import { v4 as uuid } from 'uuid';

import S3 from 'aws-sdk/clients/s3';

import S3Client from 'configs/s3';

import { IS3Progress } from './s3.types';

const thumbnailUrl = process.env.REACT_APP_AWS_THUMBNAIL;

const uploadS3 = (props: {
  file: File;
  key: string;
  bucket: string;
  progress: (parameter: S3.ManagedUpload.Progress) => void;
}): Promise<Error | S3.ManagedUpload.SendData> => {
  const { bucket, file, key, progress } = props;
  return new Promise((resolve, reject) => {
    const managedUpload = S3Client.upload(
      {
        Key: key,
        Body: file,
        Bucket: bucket,
        ContentType: file.type,
        Metadata: {
          Name: encodeURI(file.name),
        },
      },
      {
        partSize: 10 * 1024 * 1024,
        queueSize: 10,
      },
      (err, response) => {
        if (err) return reject(err);
        return resolve(response);
      },
    );

    managedUpload.on('httpUploadProgress', progress);
  });
};

export const uploadFiles = async (props: {
  bucket: string;
  files: FileList | File[];
  onProgress?: (value: IS3Progress) => void;
  onComplete?: (responses: {
    [key: string]: S3.ManagedUpload.SendData;
  }) => void;
  onSingleUpload?: (
    fileName: string,
    fileType: string,
    fileSize: number,
    response: S3.ManagedUpload.SendData,
  ) => void;
}) => {
  const { bucket, files, onComplete, onProgress, onSingleUpload } = props;

  const responses: { [key: string]: S3.ManagedUpload.SendData } = {};

  onProgress &&
    onProgress({
      currentFile: 0,
      totalFiles: files.length,
      loadedInPercentage: 0,
      isDone: false,
    });

  let index = 0;
  for await (const file of files) {
    let key = uuid();
    if (/mp4/.test(file.type)) key = `videos/${key}`;

    const response = await uploadS3({
      key,
      file,
      bucket,
      // eslint-disable-next-line no-loop-func
      progress: (progress) => {
        onProgress &&
          onProgress({
            currentFile: index,
            totalFiles: files.length,
            loadedInPercentage: Number(
              ((progress.loaded / progress.total) * 100).toPrecision(3),
            ),
            isDone: false,
          });
      },
    });

    index += 1;

    if (!(response instanceof Error)) {
      responses[file.name] = response;
      onSingleUpload &&
        onSingleUpload(file.name, file.type, file.size, response);
    }
  }

  onProgress &&
    onProgress({
      currentFile: index,
      totalFiles: files.length,
      loadedInPercentage: 100,
      isDone: true,
    });

  onComplete && onComplete(responses);
};

export const getThumbnailFromS3URL = (
  url: string,
  width: number = 200,
  height: number = 200,
  bucket: string | undefined = process.env.REACT_APP_S3_BUCKET,
) => {
  if (!url) {
    return url;
  }
  const mediaId = url.split('/').slice(-1).pop();
  return `${thumbnailUrl}/?key=${mediaId}&bucketName=${bucket}&width=${width}&height=${height}`;
};
