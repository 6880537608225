import dayjs from 'dayjs';
import { client } from 'configs/apollo';
import { EKey } from 'utils/utils.types';

import { IToken } from './token.types';

export const getTokenFromLocalStorage = () => localStorage.getItem(EKey.TOKEN);
export const setTokenInLocalStorage = (token?: string | null) =>
  !!token
    ? localStorage.setItem(EKey.TOKEN, token)
    : localStorage.removeItem(EKey.TOKEN);

export const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const encodeJwt = (payload: Object) => {
  const head = btoa(
    JSON.stringify({
      alg: 'HS256',
    }),
  );
  const tail = btoa(String(Math.random()));
  return `${head}.${btoa(JSON.stringify(payload))}.${tail}`;
};

export const isTokenExpired = (token: string) => {
  try {
    const tokenContent: IToken = parseJwt(token);
    return !tokenContent
      ? true
      : dayjs(tokenContent.exp * 1_000).diff(dayjs()) < 0;
  } catch (ex) {
    console.error(ex);
    return true;
  }
};

export const logout = async (redirect = true) => {
  client.resetStore();
  setTokenInLocalStorage(null);
  localStorage.removeItem(EKey.USER_ID);

  if (redirect) window.location.href = '/sign-in';
};
