import {
  parseBool,
  exitFullScreen,
  atomWithToggle,
  requestFullScreen,
} from 'general/helper';
import { atom } from 'jotai';
import { ElementType, createContext, useCallback, memo, useState } from 'react';

export interface AppContextTypes {
  volume: number;
  settings: any;
  fullScreen: boolean;
  duplicateUser: boolean;
  sessions: any;
  updateSessions: (value: any) => void;
  updateVolume: (value: number) => void;
  updateUserSettings: (object: any) => void;
  toggleFullScreen: (value: boolean) => void;
  setDuplicateUser: (value: boolean) => void;
  updateSettings: (key: string, value: string | boolean) => void;
}

export const FullscreenAtom = atomWithToggle(false);
export const FullscreenWithDocumentFullscreenAtom = atom(
  (get) => get(FullscreenAtom),
  (get, set, _arg) => {
    !Boolean(get(FullscreenAtom)) ? requestFullScreen() : exitFullScreen();
    set(FullscreenAtom, !Boolean(get(FullscreenAtom)));
  },
);

const AppContext = createContext<AppContextTypes>({
  volume: 100,
  settings: null,
  fullScreen: false,
  duplicateUser: false,
  sessions: [],
  updateSessions: () => null,
  updateVolume: () => null,
  updateSettings: () => null,
  toggleFullScreen: () => null,
  setDuplicateUser: () => null,
  updateUserSettings: () => null,
});

const AppProvider: ElementType = memo(({ children }) => {
  const [volume, setVolume] = useState<number>(100);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [duplicateUser, setDuplicateUser] = useState<boolean>(false);
  const [sessions, setSessions] = useState<any>([]);
  const [settings, setSettings] = useState<any>({
    webcam: localStorage.getItem('webcam'),
    microphone: localStorage.getItem('microphone'),
    isWebcamActive: parseBool(localStorage.getItem('isWebcamActive')),
    isMicrophoneActive: parseBool(localStorage.getItem('isMicrophoneActive')),
    screenShare: false,
    raiseHand: false,
  });

  const updateVolume = useCallback((_volume: number) => {
    setVolume(_volume);
  }, []);

  const toggleFullScreen = useCallback((value: boolean) => {
    setFullScreen(value);
  }, []);

  const updateSessions = useCallback((sessions: any) => {
    setSessions(sessions);
  }, []);

  const updateUserSettings = useCallback((updatedSettings) => {
    setSettings((_settings: any) => ({
      ..._settings,
      updatedSettings,
    }));
    Object.entries(updatedSettings).forEach((key, value) => {});
    for (const [key, value] of Object.entries(updatedSettings)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, []);

  const updateSettings = useCallback((key: string, value: string | boolean) => {
    setSettings((_settings: any) => ({
      ..._settings,
      [key]: value,
    }));
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  return (
    <AppContext.Provider
      value={{
        volume,
        settings,
        fullScreen,
        duplicateUser,
        sessions,
        updateSessions,
        updateVolume,
        updateSettings,
        toggleFullScreen,
        setDuplicateUser,
        updateUserSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
});

export { AppContext, AppProvider };
