import S3 from 'aws-sdk/clients/s3';

const REACT_APP_AWS_S3_REGION = process.env.REACT_APP_AWS_S3_REGION!;
const REACT_APP_AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY!;
const REACT_APP_AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;

const S3Client = new S3({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REACT_APP_AWS_S3_REGION,
});

export default S3Client;
