import isNil from 'lodash/isNil';
import { QueryHookOptions } from '@apollo/client';

import { FETCH_ORGANIZATION } from './queries';

import { useQuery } from '@apollo/client/react/hooks/useQuery';

import { IOrganization } from 'models/organization.models';

function useOrganization(
  options?: QueryHookOptions<{ getOrganization: IOrganization }>,
) {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<{
    getOrganization: any;
  }>(FETCH_ORGANIZATION, options);

  const access: IOrganization['access'] =
    data?.getOrganization?.accessAllowed.reduce(
      (acc: { [key: string]: boolean }, value: string) => {
        return {
          ...acc,
          [value]: true,
        };
      },
      {},
    ) || {};

  const organization: IOrganization = {
    ...data?.getOrganization,
    access,
  };

  return {
    data: isNil(data?.getOrganization) ? null : organization,
    loading,
    error,
    fetchMore,
    networkStatus,
  };
}

export { useOrganization };
