import { gql } from '@apollo/client';

export const FETCH_ORGANIZATION = gql`
  query getOrganization {
    getOrganization {
      id
      name
      tagLine
      website
      adminEmail
      primaryColor
      secondaryColor
      imageUrl
      domain
      privacyPolicy
      disclaimer
      imprint
      bucketEU
      bucketHK
      accessAllowed
      watermarkImage {
        id
        url
        original
        size
        opacity
        distance
      }
      streamProvider
    }
  }
`;
