import { lazy, Suspense } from "react";
import Loader from "components/loader";

const LazyView = lazy(() => import("./view"));

const Component: React.FC<any> = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyView {...props} />
  </Suspense>
);

export default Component;
