import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import ReactGA from 'react-ga';
import GlobalStyles from 'styles/global';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import Routes from './routes';

import { theme } from 'assets/theme';
import { client } from 'configs/apollo';

dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(tz);

const propertyId: string = process.env.REACT_APP_GA_PROPERTY_KEY || '';
ReactGA.initialize(propertyId);

// @ts-ignore
window.__PUBLISHERS__ = [];

// @ts-ignore
window.__VIEWERS__ = [];

const App = () => {
  useEffect(() => {
    const userId = localStorage.getItem('userId') || '-';
    ReactGA.set({ dimension1: userId });
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
