import { FC, createContext, PropsWithChildren } from 'react';

import { theme } from 'assets/theme';
import Loader from 'components/loader';
import NoMatch from 'routes/noMatch/component';
import ServerError from 'routes/ServerError/component';
import ConfigProvider from 'antd/es/config-provider';

import { useOrganization } from 'services/graphql/organization/hooks';

import { IOrganization } from 'models/organization.models';

interface IOrganizationContext {
  organization: IOrganization;
}

export const OrganizationContext = createContext<IOrganizationContext | null>(
  null,
);

export const OrganizationProvider: PropsWithChildren<FC> = ({ children }) => {
  const {
    error,
    loading,
    data: organization,
    networkStatus,
  } = useOrganization({ errorPolicy: 'ignore' });

  if (loading) return <Loader />;
  if (error && networkStatus === 8) return <ServerError />;
  if (!organization) return <NoMatch />;

  (() => {
    localStorage.setItem('imageUrl', organization.imageUrl);
    localStorage.setItem('primaryColor', organization.primaryColor);
    localStorage.setItem('secondaryColor', organization.secondaryColor);

    theme.color.primary = organization.primaryColor;
    theme.color.secondary = organization.secondaryColor;

    ConfigProvider.config({
      theme: {
        primaryColor: theme.color.primary,
      },
    });
  })();

  return (
    <OrganizationContext.Provider value={{ organization }}>
      {children}
    </OrganizationContext.Provider>
  );
};
