import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Heading } from 'components/typography';
import { Logo } from 'styles/layout';
import { WelcomeContainer, SubmitButton } from './index.styled';
import { useContext } from 'react';
import { OrganizationContext } from 'context/organization.context';

const Welcome = () => {
  const { organization } = useContext(OrganizationContext)!;
  const { t: translation } = useTranslation();

  return (
    <WelcomeContainer>
      <Logo src={organization.imageUrl} />
      <Heading level={2}>
        Welcome to our
        <br />
        {organization.tagLine}
      </Heading>
      {organization.access.SHOWROOM && (
        <Link to="/welcome">
          <SubmitButton>{translation('Start')}</SubmitButton>
        </Link>
      )}
      {!organization.access.SHOWROOM && (
        <Link to="/login">
          <SubmitButton>{translation('Login')}</SubmitButton>
        </Link>
      )}
    </WelcomeContainer>
  );
};

export default Welcome;
