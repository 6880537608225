export const size = {
  xs: 0,
  sm: 378,
  md: 640,
  lg: 968,
  xl: 1200,
};

export const device = {
  tiny: `(max-width: ${size.sm - 1}px)`,
  mobile: `(max-width: ${size.md - 1}px), (max-height: 460px)`,
  tablet: `(min-width: ${size.md}px) and (max-width: ${size.lg - 1}px)`,
  laptop: `(min-width: ${size.lg}px)`,
  desktop: `(min-width: ${size.xl}px)`,
};
