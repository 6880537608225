export const theme = {
  color: {
    primary: localStorage.getItem('primaryColor') || '#0060FF',
    secondary: localStorage.getItem('secondary') || '#ffffff',
    red: '#EB5757',
    redDark: '#CE3800',
    orange: '#FF9927',
    yellow: '#FEC037',
    green: '#00800D',
    white: '#ffffff',
    black: '#262523',
    gray: '#D1D5DE',
    blue: '#0000EE',
    lightGray: '#B7BDCB',
    lightGray2: '#f2e5d1',
    darkGray1: '#F5F3F1',
    darkGray2: '#E6E2DC',
    darkGray3: '#E9EBEF',
    darkGray4: '#8F887E',
    darkGray5: 'rgba(245, 243, 241, 0.5)',
    darkGray6: '#615C54',
    darkGray7: '#F7F5F4',
    darkGray8: '#3F3E3C',
    darkGray9: '#767067',
    darkGray10: '#80807D',
  },
  fontFamily: {
    slim: { fontFamily: 'Noto+Sans', fontWeight: 100 },
    regular: { fontFamily: 'Noto+Sans', fontWeight: 500 },
    medium: { fontFamily: 'Noto+Sans', fontWeight: 600 },
    semiBold: { fontFamily: 'Noto+Sans', fontWeight: 700 },
    bold: { fontFamily: 'Noto+Sans', fontWeight: 900 },
  },
};
