import { IMediaType } from 'models/media.modes';

export const getExtension = (key: string) =>
  /[.]/.exec(key) ? /[^.]+$/.exec(key)![0] : '';

export const getType = (mimeType: string) => {
  if (/video/.test(mimeType) || /mpegurl/.test(mimeType))
    return IMediaType.videos;
  else if (/image/.test(mimeType)) return IMediaType.image;
  else if (/presentation/.test(mimeType)) return IMediaType.presentation;
  else if (/pdf/.test(mimeType)) return IMediaType.pdf;
  else return IMediaType.others;
};
export function generateName(email: string) {
  const getEmailFragments = (email: string) => email.split(/@(?=[^@]*$)/);
  const [name] = getEmailFragments(email);
  const regexs = ['.', /-(.+)/, /_(.+)/];

  let result = regexs
    .map((regex) => name.split(regex, 3))
    .filter((array) => array.length > 1);

  if (result.length) {
    let _firstName, _lastName;

    if (result.length > 1) result.splice(1, 1);

    _firstName = result[0][0].charAt(0).toUpperCase() + result[0][0].slice(1);

    if (result[0][2] === undefined || result[0][2] === '') {
      if (result[0][1].includes('-')) {
        let afterSplit = result[0][1].split('-');
        _lastName =
          afterSplit[0].charAt(0).toUpperCase() +
          afterSplit[0].slice(1) +
          '-' +
          afterSplit[1].charAt(0).toUpperCase() +
          afterSplit[1].slice(1);
      } else {
        _lastName =
          result[0][1].charAt(0).toUpperCase() + result[0][1].slice(1);
      }
    } else {
      if (result[0][1].length < 3) {
        _lastName =
          result[0][1].charAt(0).toUpperCase() +
          result[0][1].slice(1) +
          '. ' +
          result[0][2].charAt(0).toUpperCase() +
          result[0][2].slice(1);
      } else {
        if (result[0][1].includes('-')) {
          let afterSplit = result[0][1].split('-');
          _lastName =
            afterSplit[0].charAt(0).toUpperCase() +
            afterSplit[0].slice(1) +
            '-' +
            afterSplit[1].charAt(0).toUpperCase() +
            afterSplit[1].slice(1) +
            result[0][2].charAt(0).toUpperCase() +
            result[0][2].slice(1);
        } else {
          _lastName =
            result[0][1].charAt(0).toUpperCase() +
            result[0][1].slice(1) +
            ' ' +
            result[0][2].charAt(0).toUpperCase() +
            result[0][2].slice(1);
        }
      }
    }

    return {
      firstName: _firstName,
      lastName: _lastName,
    };
  }
  return {
    firstName: name.charAt(0).toUpperCase() + name.slice(1),
    lastName: '',
  };
}

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);

export const fixLineBreaks = (content: string) =>
  content.replace(/<p>(<br>)+<\/p>/g, '<br>');

export const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const isFullNameValid = (fullName: string) => {
  const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
  return regex.test(fullName);
};

export const splitName = (fullName: string) => {
  let firstName = '';
  let lastName = '';
  if (fullName.replace(/  +/g, ' ').split(' ').length > 0) {
    firstName = fullName.split(' ').slice(0, -1).join(' ');
    lastName = fullName.split(' ').slice(-1).join(' ');
  } else {
    fullName.charAt(0).toUpperCase();
    firstName = fullName;
  }
  if (firstName)
    firstName =
      firstName.charAt(0).toUpperCase() + firstName.substr(1).toLowerCase();
  if (lastName)
    lastName =
      lastName.charAt(0).toUpperCase() + lastName.substr(1).toLowerCase();
  return { firstName, lastName };
};

export const extractEmails = (
  emailsStr: string,
  separator = new RegExp(',|;|\\s', 'gi'),
) =>
  emailsStr
    .split(separator)
    .map((sub) => sub.trim())
    .filter((sub) => !!sub);

export const parseBool = (str: string | null | undefined): boolean =>
  str === 'true';

export const prefixZero = (text: string) => `0${text}`.slice(-2);

const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

export const verifyEmail = (email: string) => {
  return emailRegex.test(email?.toLowerCase()?.trim());
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const b64toBlob = (
  b64Data: string,
  filename: string,
  contentType: string,
  sliceSize?: number,
) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = window.atob(b64Data.split(',')[1]);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, filename, { type: contentType });
};
