import { useTranslation } from "react-i18next";
import { Heading } from "components/typography";
import { Container, Backdrop } from "./index.styled";

const NoMatch = () => {
  const {t : translation} = useTranslation();
  return (
    <Container>
      <Backdrop>404</Backdrop>
      <Heading level={1}>
        {translation("Oops! We couldn't find")}
        <br />
        {translation("the page you're looking for")}
      </Heading>
    </Container>
  );
};

export default NoMatch;
