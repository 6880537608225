import { client } from 'configs/apollo';
import {
  UPDATE_USER,
  DELETE_USER,
  RESEND_VERIFICATION_LINK,
  UPDATE_USER_ROLE,
} from './mutations';
import {
  LOGIN,
  FETCH_USER,
  PUBLIC_LOGIN,
  EMAIL_EXISTS,
  IQPublicLogin,
  IQVPublicLogin,
  FETCH_ALL_USERS,
  IQUsersByEmails,
  IQVUsersByEmails,
  FETCH_ALL_ADMINS,
  FETCH_USER_BY_EMAIL,
  FETCH_USER_BY_EMAILS,
  FETCH_ATTENDEE_TOKENS,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
} from './queries';
import {
  IUserUpdate,
  AdminLogin,
  Login,
  IAllUsersInput,
  IMFetchAttendeeTokens,
  IMVFetchAttendeeTokens,
} from './user.models';
import { ADD_USER } from './mutations';
import { IAddUser, AttendeeTokenInput } from './user.models';
import { IUser, IUserRoles } from 'models/user.models';
import { PaginationMetaData } from 'models/misc';

export interface ILogin {
  id: string;
  email: string;
  lastName: string;
  isAdmin: boolean;
  firstName: string;
  isVerified: boolean;

  token?: string;
  imageUrl?: string;
}
export const loginService = async (login: AdminLogin) => {
  try {
    const { email, password } = login;
    const { data } = await client.query({
      query: LOGIN,
      variables: {
        email,
        password,
      },
    });
    return data?.login;
  } catch (error) {
    return error as Error;
  }
};

export const publicLoginService = async (login: Login) => {
  try {
    const { id } = login;
    const { data } = await client.query<IQPublicLogin, IQVPublicLogin>({
      query: PUBLIC_LOGIN,
      variables: {
        id,
      },
    });
    return data?.publicLogin;
  } catch (error) {
    return error as Error;
  }
};

export const fetchUserByEmail = async (email: string) => {
  try {
    const { data } = await client.query({
      query: FETCH_USER_BY_EMAIL,
      variables: {
        email,
      },
    });
    return data?.userByEmail;
  } catch (error) {
    console.error('FETCH_USER_BY_EMAIL', error);
    return error as Error;
  }
};

export const fetchUsersByEmail = async (emails: string[]) => {
  try {
    const { data } = await client.query<IQUsersByEmails, IQVUsersByEmails>({
      query: FETCH_USER_BY_EMAILS,
      variables: {
        emails,
      },
    });
    return data?.usersByEmails;
  } catch (error) {
    console.error('FETCH_USERS_BY_EMAIL', error);
    return error as Error;
  }
};

export type IFetchedUser = Pick<
  IUser,
  'firstName' | 'lastName' | 'email' | 'isAdmin' | 'id'
>;
export const fetchUserService = async (id: string) => {
  try {
    const { data } = await client.query({
      query: FETCH_USER,
      variables: {
        id,
      },
    });
    return data?.user;
  } catch (error) {
    console.error('FETCH_USER', error);
    return {};
  }
};

export const updateUserService = async (input: IUserUpdate) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        input,
      },
    });
    return data?.updateUser;
  } catch (error) {
    console.error('UPDATE_USER', error);
    return {};
  }
};

export const addUserService = async (input: IAddUser) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_USER,
      variables: {
        input,
      },
    });
    return data?.addUser;
  } catch (error) {
    console.error('ADD_USER', error);
    return error as Error;
  }
};

export const resendVerfificationService = async (email: string) => {
  try {
    const { data } = await client.mutate({
      mutation: RESEND_VERIFICATION_LINK,
      variables: {
        email,
      },
    });
    return data?.resendVerfification;
  } catch (error) {
    console.error('RESEND_VERIFICATION_LINK', error);
    return error as Error;
  }
};

export const fetchAllAdmins = async (): Promise<
  Error | Array<Partial<IUser>>
> => {
  try {
    const { data } = await client.query({
      query: FETCH_ALL_ADMINS,
    });
    return data?.admins;
  } catch (error) {
    console.error('FETCH_ALL_ADMINS', error);
    return error as Error;
  }
};

export const deleteUserService = async (
  id: string,
): Promise<Error | Boolean> => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_USER,
      variables: {
        id,
      },
    });
    return data?.deleteUser;
  } catch (error) {
    console.error('DELETE_USER', error);
    return error as Error;
  }
};

export const fetchAllUsers = async (
  props: IAllUsersInput,
): Promise<
  | Error
  | {
      meta_data: PaginationMetaData;
      users: IUser[];
    }
> => {
  try {
    const { offset, limit, filters, sort } = props;
    const { data } = await client.query({
      query: FETCH_ALL_USERS,
      variables: {
        offset,
        limit,
        filters,
        sort,
      },
      errorPolicy: 'ignore',
    });

    return data?.users;
  } catch (error) {
    console.error('FETCH_ALL_USERS', error);
    return error as Error;
  }
};

export const fetchAttendeeTokensService = async (input: AttendeeTokenInput) => {
  try {
    const { data } = await client.mutate<
      IMFetchAttendeeTokens,
      IMVFetchAttendeeTokens
    >({
      mutation: FETCH_ATTENDEE_TOKENS,
      variables: {
        input,
      },
    });
    return data?.attendeeTokens;
  } catch (error) {
    console.error('FETCH_ATTENDEE_TOKENS', error);
    return error as Error;
  }
};

export const checkIfEmailExistService = async (
  email: string,
): Promise<Error | boolean> => {
  try {
    const { data } = await client.query({
      query: EMAIL_EXISTS,
      variables: {
        email,
      },
    });
    return data?.checkIfEmailExists;
  } catch (error) {
    console.error('EMAIL_EXISTS', error);
    return error as Error;
  }
};

export const updateUserRoleService = async (
  userId: string,
  roles: IUserRoles,
): Promise<Error | IUser> => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_USER_ROLE,
      variables: {
        userId,
        roles,
      },
    });
    return data?.updateUserRole;
  } catch (error) {
    console.error('UPDATE_USER_ROLE', error);
    return error as Error;
  }
};

export const requestResetPasswordService = async (
  email: string,
): Promise<Error | Boolean> => {
  try {
    const { data } = await client.query({
      query: REQUEST_RESET_PASSWORD,
      variables: {
        email,
      },
    });
    return data?.requestResetPassword;
  } catch (error) {
    console.error('REQUEST_RESET_PASSWORD', error);
    return error as Error;
  }
};

export const requestPasswordService = async (
  resetToken: string,
  password: string,
): Promise<Error | Boolean> => {
  try {
    const { data } = await client.query({
      query: RESET_PASSWORD,
      variables: {
        resetToken,
        password,
      },
    });
    return data?.resetPassword;
  } catch (error) {
    console.error('RESET_PASSWORD', error);
    return error as Error;
  }
};
