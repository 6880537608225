export type Awaited<T> = T extends Promise<infer Return> ? Return : T;

export type Nullable<T> = T | null;
export type NullableKeys<T> = {
  [P in keyof T]: T[P] | null;
};

export type RenameKey<I, K extends keyof I, N extends PropertyKey> = Omit<
  I,
  K
> &
  {
    [P in N]: I[K];
  };

export type ModifyKey<I, K extends keyof I, V> = Omit<I, K> &
  { [K in keyof I]: V };

/**
 * Change the type of Keys of T from NewType
 */
export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType,
> = {
  // Loop to every key. We gonna check if the key
  // is assignable to Keys. If yes, change the type.
  // Else, retain the type.
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

export type PickAndModifyKey<I, K extends keyof I, V> = ModifyKey<
  Pick<I, K>,
  K,
  V
>;
export type PickAndRenameKey<
  I,
  K extends keyof I,
  N extends PropertyKey,
> = RenameKey<Pick<I, K>, K, N>;

export type PartialDeep<T> = T extends Object
  ? { [P in keyof T]?: PartialDeep<T[P]> }
  : T;

export type ActionInput<Func extends (...args: any) => any> =
  Parameters<Func> extends [any, infer R] ? R : undefined;

export enum EKey {
  TOKEN = 'token',
  USER_ID = 'userId',
  SETTINGS_CLOSED = 'settingsClosed',
}

export enum EEntity {
  Chat = 'Chat',
  Webinar = 'Webinar',
  SystemMessage = 'SystemMessage',
  WebinarAttendee = 'WebinarAttendee',
  ThreeDStremConference = 'ThreeDStreamConference',
}

export type IEntity<T, N extends EEntity> = T & {
  __typename: N;
};
