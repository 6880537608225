import { useReducer } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USER } from './useSessionUser.queries';
import { getParsedToken, getSessionUserId } from './useSessionUser.utils';

import { IQGetUser, IQVGetUser } from './useSessionUser.types';

export const useSessionUser = () => {
  const [, forceRender] = useReducer(() => ({}), {});

  const id = getSessionUserId() || '';
  const { data, loading, error } = useQuery<IQGetUser, IQVGetUser>(GET_USER, {
    skip: !id,
    variables: {
      id,
    },
  });

  const user = data?.user || getParsedToken();

  const refetch = async () => {
    forceRender();
  };

  return { user, loading, error, refetch };
};
