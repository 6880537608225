import { WritableAtom, atom } from 'jotai';
import { Stream } from 'opentok-react/types/opentok';
import { Duration } from 'dayjs/plugin/duration';

export const isNotNull = <R>(input: R | null | undefined): input is R =>
  input !== null && typeof input !== 'undefined';

export function atomWithToggle(
  initialValue?: boolean,
): WritableAtom<boolean, boolean | undefined> {
  const anAtom = atom(initialValue, (get, set, nextValue?: boolean) => {
    const update = nextValue ?? !get(anAtom);
    set(anAtom, update);
  });

  return anAtom as WritableAtom<boolean, boolean | undefined>;
}

export const readableDuration = (duration: Duration) =>
  `${duration.format('HH')}h ${duration.format('mm')}m`;

export const bytesToSize = (bytes: number) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) + '');
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

export const capitalizeWithSpace = (str: string, splitChar: string) => {
  if (!str.length) return '';
  const splitVal = str.split(splitChar);
  const capitalize = splitVal.map((v: string) => {
    const firstCharacter = v[0].toUpperCase();
    const restOfTheWord = v.slice(1, v.length).toLowerCase();
    return `${firstCharacter}${restOfTheWord}`;
  });
  return capitalize.join(' ');
};

export const parseConnectionData = (
  data: string,
): { email: string; camera: string; microphone: string; os: string } =>
  JSON.parse(data);

export const extractEmailFromVonage = (stream: Stream) =>
  parseConnectionData(stream.connection.data).email;

export const isEnvProduction = () =>
  process.env.REACT_APP_PRODUCTION === 'TRUE';

export const objectByKeys = (item: any, key: string) => {
  if (!item) return {};
  const data = item[key]?.reduce((accu: object, _item: any) => {
    const _child = objectByKeys(_item, key);
    return {
      [item.id]: item,
      [_item.id]: _item,
      ..._child,
      ...accu,
    };
  }, {});
  return data;
};
