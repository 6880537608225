import { useContext } from 'react';

import { UserContext } from 'context/user';
import { EKey, setTokenInLocalStorage } from 'general/helper';

import { publicLoginService } from 'services/graphql/user/services';

import { IUsePublicLogin } from './types';

const usePublicLogin: IUsePublicLogin = () => {
  const { refetch } = useContext(UserContext);
  const publicLogin = async (userId: string) => {
    const response = await publicLoginService({ id: userId });
    if (response instanceof Error) throw response;

    const loginData = response;
    if (loginData.status === 'Disabled')
      throw new Error(
        'Your account has been disabled. Please contact support for more details.',
      );

    const { token } = loginData;
    setTokenInLocalStorage(token);
    localStorage.setItem(EKey.USER_ID, loginData.id);

    refetch();
  };

  return {
    publicLogin,
  };
};

export default usePublicLogin;
