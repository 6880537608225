import { IUser } from './user.models';

export enum IMediaAccessType {
  public = 'public',
  private = 'private',
}

export enum IMediaStatusType {
  aborted = 'aborted',
  initialized = 'initialized',
  none = 'none',
  ok = 'ok',
}

export enum IMediaType {
  image = 'image',
  videos = 'videos',
  presentation = 'presentation',
  text = 'text',
  pdf = 'pdf',
  vector = 'vector',
  others = 'others',
}

export enum IMediaStatus {
  completed = 'completed',
  processing = 'processing',
}

export interface IMedia {
  id: string;
  name: string;
  url: string;
  ext: string;
  status: IMediaStatusType;
  mediaType: IMediaType;
  accessType: IMediaAccessType;
  user: IUser;
  createdOn: string;
  updatedOn: string;
  etag: string;
  bucket: string;
  key: string;
  fileSize: number;
  chinaVideoUrl: string;
  transcriptGenerated?: boolean;
  transcriptionUrl?: string;
  newVideoUrl?: string;
  contentType?: string;
  isExternal?: string;
  m3u8Url?: string;
}

export interface IMediaDirectory {
  id: string;
  name: string;
  type: IMediaDirectoryType;
  createdOn: string;
  updatedOn: string;
  parent: IMediaDirectory;
  media: IMedia;
  parentId: string;
  accessType: IMediaAccessType;
  readOnly: boolean;
  user?: IUser;
}

export enum IMediaDirectoryType {
  Root = 'Root',
  Folder = 'Folder',
  File = 'File',
  SymLink = 'SymLink',
  Bucket = 'Bucket',
  Shared = 'Shared',
}

export interface IRootMediaDirectory {
  id: string;
  name: string;
  type: IMediaDirectoryType;
  createdOn: String;
  updatedOn: String;
  parent: IMediaDirectory;
  user: IUser;
  media: IMedia;
  children: IRootMediaDirectory[];
  parentId: string;
  accessType: IMediaAccessType;
}

export interface ISharedContentStats {
  usersCount: number;
  prevUsersCount: number;
  openedLinksCount: number;
  unopenedLinksCount: number;
}
