import styled, { CSSProperties } from 'styled-components';
import lodashPick from 'lodash/pick';
import { device } from './devices';

export const View = styled.main`
  display: flex;
  flex: 1;
  overflow: auto;
`;

export const Sidebar = styled.aside<any>`
  display: flex;
  flex-shrink: 1;
  width: 370px;
  min-width: 370px;
  background: ${({ theme }) => theme.color.primary};
  height: 100%;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.section<any>`
  position: relative;

  display: flex;
  justify-content: ${({ position }) => position || 'center'};
  align-items: ${({ position }) => position || 'center'};
  padding: 0px 80px;
  flex: 1;
  overflow-x: auto;

  @media (max-width: 1300px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
`;

export const HiddenInput = styled.input`
  width: 200px;
  padding: 0;
  border: none;
  outline: none;
  color: transparent !important;
  position: absolute;
  z-index: -1;
  height: 1px;
  bottom: 1px;
  left: 0px;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0px 15px;
    color: ${({ theme }) => theme.color.white};
    opacity: 0.8;
    transition: all 0.3s linear;
    font-size: 12px;
    ${({ theme }) => {
      return { ...theme.fontFamily.regular };
    }};
    line-height: 1;
    @media ${device.mobile} {
      color: black;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

export const DialogWrapper = styled.div<any>`
  * {
    animation-duration: 0s !important;
    animation: none;
  }

  .ant-modal-wrap {
    top: 0;
    left: 0;
    bottom: 0px;
  }

  @media (max-width: 1400px) {
    .ant-modal-wrap {
      padding-left: 0px;
    }
  }
`;

export const Flex = styled.div<
  Pick<
    CSSProperties,
    | 'alignItems'
    | 'justifyContent'
    | 'flexDirection'
    | 'flex'
    | 'gap'
    | 'height'
    | 'alignSelf'
    | 'justifySelf'
    | 'overflow'
  >
>`
  display: flex;
  > * {
    margin-bottom: unset;
  }

  ${(props) =>
    lodashPick(props, [
      'alignSelf',
      'alignItems',
      'justifyContent',
      'flexDirection',
      'flex',
      'gap',
      'height',
      'overflow',
    ])}
`;

export const Stack = styled(Flex)`
  flex-direction: column;
  ${(props) =>
    lodashPick(props, ['alignItems', 'justifyContent', 'flexDirection', 'gap'])}
`;
