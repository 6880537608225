import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from "react";
import NetworkTest from "opentok-network-test-js";
import { ConnectivityTestResults } from "opentok-network-test-js/dist/NetworkTest/testConnectivity";
import { QualityTestResults } from "opentok-network-test-js/dist/NetworkTest/testQuality";
import { SubscriberStats } from "opentok-network-test-js/dist/NetworkTest/types/opentok/subscriber";

export interface ITestState {
  isVideoAvailable: boolean;
  isAudioAvailable: boolean;
  isError: boolean;
  error: any | null;
  isSuccessfull: boolean;
  qualityTestResults: QualityTestResults | null;
  connectivityTestResult: ConnectivityTestResults | null;
  subscriberStats: SubscriberStats | null;
  state: "idle" | "pending" | "resolved" | "rejected" | "testing";
}

export enum ActionKind {
  UPDATE = "UPDATE",
}

export interface Action {
  type: ActionKind;
  payload?: Partial<ITestState>;
}

function useSafeDispatch(dispatch: React.Dispatch<Action>) {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback(
    (args: Action) => (mounted.current ? dispatch(args) : void 0),
    [dispatch]
  );
}

const dispatchError = (
  dispatch: React.Dispatch<Action>,
  message: string,
  error: any
) =>
  dispatch({
    type: ActionKind.UPDATE,
    payload: { error: { message, error }, state: "rejected", isError: true },
  });

const dispatchPending = (dispatch: React.Dispatch<Action>) =>
  dispatch({ type: ActionKind.UPDATE, payload: { state: "pending" } });

const updateConnectivityTestResults = (
  dispatch: React.Dispatch<Action>,
  connectivityTestResult: ConnectivityTestResults
) =>
  dispatch({
    type: ActionKind.UPDATE,
    payload: { connectivityTestResult, state: "testing" },
  });

const updateSubscriberTestResults = (
  dispatch: React.Dispatch<Action>,
  subscriberStats: SubscriberStats
) =>
  dispatch({
    type: ActionKind.UPDATE,
    payload: { subscriberStats, state: "testing" },
  });

const updateTestResults = (
  dispatch: React.Dispatch<Action>,
  qualityTestResults: QualityTestResults,
  isVideoAvailable: boolean,
  isAudioAvailable: boolean
) =>
  dispatch({
    type: ActionKind.UPDATE,
    payload: {
      state: "resolved",
      qualityTestResults,
      isVideoAvailable,
      isAudioAvailable,
      isSuccessfull: true,
    },
  });

const updateWhenOnlyConnectionEstablished = (
  dispatch: React.Dispatch<Action>,
  connectivityTestResult: ConnectivityTestResults
) =>
  dispatch({
    type: ActionKind.UPDATE,
    payload: {
      state: "resolved",
      connectivityTestResult,
      isSuccessfull: true,
    },
  });

function Reducer(state: ITestState, action: Action): ITestState {
  const { type, payload } = action;
  switch (type) {
    case ActionKind.UPDATE: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}

const initiialState: ITestState = {
  isVideoAvailable: false,
  isAudioAvailable: false,
  isError: false,
  error: null,
  isSuccessfull: false,
  qualityTestResults: null,
  state: "idle",
  subscriberStats: null,
  connectivityTestResult: null,
};

function useTestHook(
  otNetworkTest: NetworkTest,
  disableAudioVideoTest: boolean = false
) {
  const [state, dispatch] = useReducer(Reducer, initiialState);

  const safeSetState = useSafeDispatch(dispatch);

  useEffect(() => {
    dispatchPending(safeSetState);
    otNetworkTest
      .testConnectivity()
      .then((results) => {
        !disableAudioVideoTest &&
          updateConnectivityTestResults(safeSetState, results);
        console.info("OpenTok connectivity test results", results);

        if (disableAudioVideoTest) {
          updateWhenOnlyConnectionEstablished(safeSetState, results);
          return;
        }

        otNetworkTest
          .testQuality(function updateCallback(stats) {
            updateSubscriberTestResults(safeSetState, stats);
            console.info("intermediate testQuality stats", stats);
          })
          .then((results) => {
            let isVideoAvailable = true;
            let isAudioAvailable = true;

            if (results.video.reason) {
              console.error("Video not supported:", results.video.reason);
              isVideoAvailable = false;
            }
            if (!results.audio.supported) {
              console.error("Audio not supported:", results.audio.reason);
              isAudioAvailable = false;
            }

            updateTestResults(
              safeSetState,
              results,
              isVideoAvailable,
              isAudioAvailable
            );
          })
          .catch((error) => {
            console.error("OpenTok quality test error", error);
            dispatchError(safeSetState, "OpenTok quality test error", error);
          });
      })
      .catch((error) => {
        console.error("OpenTok connectivity test error", error);
        dispatchError(safeSetState, "OpenTok connectivity test error", error);
      });
  }, [disableAudioVideoTest, otNetworkTest, safeSetState]);

  return state;
}

export default useTestHook;
