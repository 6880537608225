import { Suspense, lazy } from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import { AppProvider } from 'context/app';
import { UserProvider } from 'context/user';
import { MeetingProvider } from 'context/meeting';
import { UserRegistrationProvider } from 'context/userRegistration';
import { ModalManagerProvider } from 'context/modalManager.context';

import Route from './route';
import Logout from './logout';
import Signin from './signIn';
import Confirmation from './confirmation';
import Meeting from './meeting';
import AdminLogin from './adminLogin';
import ResetPassword from './ResetPasswordRoute';
import SetPassword from './SetPassword';
import Thankyou from './thankyou';
import Home from './home';
import Welcome from './welcome';
import PublicRoute from './public';
import PrivateRoute from './private';
import NoMatch from './noMatch';
import Showroom from './ShowRoom';
import Premier from './Premier';
import NetworkTest from './testNetwork';
import AdminPanel from './adminPanel';
import Signup from './signUp';
import Verify from './verifyEmail';
import NotInvited from './notInvited';
import InviteUsers from './inviteUsers';
import ManageStreams from './streams3d';
import Stream from './threeDStream';
import StreamControls from './threeDStreamControls';
import StreamControls1 from './threeDStreamControlsIframe';
import PreRecorder from './PreRecorder';
import _Error from './error';
import Loader from 'components/loader';
import { OrganizationProvider } from 'context/organization.context';

const WebinarRoute = lazy(() => import('./WebinarRoute'));

const Routes = () => {
  return (
    <OrganizationProvider>
      <AppProvider>
        <UserProvider>
          <MeetingProvider>
            <UserRegistrationProvider>
              <ModalManagerProvider>
                <Router>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <PrivateRoute
                        exact
                        path="/manage_users/invite"
                        component={InviteUsers}
                        admin={true}
                      />
                      <PrivateRoute
                        exact
                        path="/error/:type"
                        component={_Error}
                      />
                      <PrivateRoute
                        exact
                        path="/notinvited/:type"
                        component={NotInvited}
                      />
                      <Route exact path="/" component={Home} />
                      <Route
                        exact
                        path="/welcome"
                        component={Welcome}
                        access={['SHOWROOM']}
                      />
                      <PublicRoute exact path="/sign-in" component={Signin} />
                      <PublicRoute exact path="/sign-up" component={Signup} />
                      <PublicRoute
                        exact
                        path="/verify-email"
                        component={Verify}
                      />
                      <PublicRoute
                        exact
                        path="/confirmation/:token"
                        component={Confirmation}
                      />

                      <Route
                        path="/webinars/:slug"
                        component={WebinarRoute}
                        access={['WEBINAR']}
                      />
                      <PrivateRoute
                        admin
                        path="/admin"
                        component={AdminPanel}
                      />
                      <PublicRoute
                        admin
                        path="/reset-password"
                        component={ResetPassword}
                      />
                      <PrivateRoute
                        admin
                        path="/setPassword"
                        component={SetPassword}
                      />
                      <Route
                        exact
                        path="/meetings/:meetingId"
                        component={Meeting}
                        access={['MEETING']}
                      />
                      <PrivateRoute
                        exact
                        path="/thankyou"
                        component={Thankyou}
                      />
                      <PrivateRoute
                        exact
                        path="/meetings/:meetingId/thankyou"
                        component={Thankyou}
                      />
                      <PrivateRoute
                        exact
                        path="/meetings/:meetingId/thankyou/:isEnded"
                        component={Thankyou}
                      />
                      <Route
                        exact
                        path="/premier/:premierId"
                        component={Premier}
                        access={['PREMIER']}
                      />
                      <Route
                        exact
                        path="/showroom/*"
                        component={Showroom}
                        access={['SHOWROOM']}
                      />
                      <Route
                        exact
                        path="/showroom"
                        component={Showroom}
                        access={['SHOWROOM']}
                      />
                      <PrivateRoute
                        path="/networktest"
                        component={NetworkTest}
                      />
                      <PrivateRoute
                        exact
                        path="/streams/manage"
                        component={ManageStreams}
                        admin={true}
                        access={['THREEDSTREAM']}
                      />
                      <Route
                        admin
                        exact
                        path="/stream/:streamSlug"
                        component={Stream}
                        access={['THREEDSTREAM']}
                      />
                      <Route
                        exact
                        component={StreamControls}
                        path="/stream/:streamSlug/controls"
                        access={['THREEDSTREAM']}
                      />
                      <Route
                        exact
                        component={StreamControls1}
                        path="/stream/:streamSlug/controls1"
                        access={['THREEDSTREAM']}
                      />
                      <PrivateRoute
                        exact
                        path="/pre-recorder"
                        component={PreRecorder}
                      />

                      <PrivateRoute exact path="/en/:id" access={['SHOWROOM']}>
                        <Redirect to="/showroom" />
                      </PrivateRoute>
                      <Route exact path="/login" component={AdminLogin} />
                      <Route exact path="/logout" component={Logout} />
                      <Route component={NoMatch} />
                    </Switch>
                  </Suspense>
                </Router>
              </ModalManagerProvider>
            </UserRegistrationProvider>
          </MeetingProvider>
        </UserProvider>
      </AppProvider>
    </OrganizationProvider>
  );
};

export default Routes;
