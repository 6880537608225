import { ElementType, createContext, memo, useState } from "react";

export interface UserRegistrationTypes {
  salutation: any;
  firstName: any;
  lastName: any;
  company: any;
  country: any;
  email: any;
  updateSalutation: (value: any) => void;
  updateFirstName: (value: any) => void;
  updateLastName: (value: any) => void;
  updateCompany: (value: any) => void;
  updateCountry: (value: any) => void;
  updateEmail: (value: any) => void;
}
const UserRegistrationContext = createContext<UserRegistrationTypes>({
  salutation: null,
  firstName: null,
  lastName: null,
  company: null,
  country: null,
  email: null,
  updateSalutation: () => null,
  updateFirstName: () => null,
  updateLastName: () => null,
  updateCompany: () => null,
  updateCountry: () => null,
  updateEmail: () => null,
});

const UserRegistrationProvider: ElementType = memo(({ children }) => {
  const [salutation, setSalutation] = useState<any>(null);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [country, setCountry] = useState<any>(null);
  const [email, setEmail] = useState<any>("");

  const updateSalutation = (_salutation: any) => {
    setSalutation(_salutation);
  };
  const updateFirstName = (_firstName: any) => {
    setFirstName(_firstName);
  };
  const updateLastName = (_lastName: any) => {
    setLastName(_lastName);
  };
  const updateCompany = (_company: any) => {
    setCompany(_company);
  };
  const updateCountry = (_country: any) => {
    setCountry(_country);
  };
  const updateEmail = (_email: any) => {
    setEmail(_email);
  };

  return (
    <UserRegistrationContext.Provider
      value={{
        salutation,
        firstName,
        lastName,
        company,
        country,
        email,
        updateCompany,
        updateCountry,
        updateEmail,
        updateFirstName,
        updateLastName,
        updateSalutation,
      }}
    >
      {children}
    </UserRegistrationContext.Provider>
  );
});

export { UserRegistrationContext, UserRegistrationProvider };
