import { FC } from 'react';

import { Heading } from 'components/typography';

import Loading from './loadingIcon';

import { Container, Wrapper } from './index.styled';

import { ILoaderProps } from './types';

const Loader: FC<ILoaderProps> = ({ label, fixed = true, className }) => {
  return (
    <Container fixed={fixed} className={className}>
      <Wrapper>
        {label && <Heading level={4}>{label}</Heading>}
        <Loading />
      </Wrapper>
    </Container>
  );
};

export default Loader;
