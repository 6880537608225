import { lazy, Suspense } from "react";
import Loader from "components/loader";
import Bar from "assets/images/bar.jpg";
import BarWaiting from "assets/images/bar-waiting.jpg";
import Cafe from "assets/images/cafe.jpg";
import CafeWaiting from "assets/images/cafe-waiting.jpg";
import Lounge from "assets/images/lounge.jpg";
import LoungeWaiting from "assets/images/lounge-waiting.jpg";
import ShowRoom from "assets/images/showroom.png";

const LazyView = lazy(() => import("./view"));

const Component: React.FC<any> = (props) => (
  <Suspense fallback={<Loader />}>
    <link rel="preload" href={Bar} as="image" />
    <link rel="preload" href={BarWaiting} as="image" />
    <link rel="preload" href={Cafe} as="image" />
    <link rel="preload" href={CafeWaiting} as="image" />
    <link rel="preload" href={Lounge} as="image" />
    <link rel="preload" href={LoungeWaiting} as="image" />
    <link rel="preload" href={ShowRoom} as="image" />
    <LazyView {...props} />
  </Suspense>
);

export default Component;
