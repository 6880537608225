import { ElementType, createContext, useState } from "react";

export interface MeetingContextTypes {
  showSettings: boolean;
  setShowSettings: any;
}

const MeetingContext = createContext<MeetingContextTypes>({
  showSettings: true,
  setShowSettings: () => false,
});

const MeetingProvider: ElementType = ({ children }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  return (
    <MeetingContext.Provider
      value={{
        showSettings,
        setShowSettings,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

export { MeetingContext, MeetingProvider };
