import styled from 'styled-components';
import Button from 'components/button';
import { device } from 'styles/devices';

export const WelcomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    color: ${({ theme }) => theme.color.white};
    text-align: center;
  }
  > svg {
    width: 280px;
    margin: unset;
    margin-bottom: 50px;
    @media ${device.mobile} {
      margin-bottom: 30px;
      width: 160px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  font-size: 16px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.black};
  }
`;
