import { useReducer, FC, createContext, Dispatch } from 'react';

import produce from 'immer';

// * ENUMS

export enum FORM {
  SCHEDULE_FORM = 'SCHEDULE_FORM',
  EMAIL_SHARE_FORM = 'EMAIL_SHARE_FORM',
}
export enum ActionKind {
  SHOW,
  HIDE,
}

// * INTERFACES

export interface IState {
  modalVisible?: FORM;
}
export interface IAction {
  type: ActionKind;
  payload?: Partial<IState>;
}

// * DEFAULTS

const defaultState: IState = {};

// * ACTIONS

export const hideModal = (dispatch: Dispatch<IAction>) =>
  dispatch({
    type: ActionKind.HIDE,
  });

export const showModal = (dispatch: Dispatch<IAction>, modal: FORM) =>
  dispatch({
    type: ActionKind.SHOW,
    payload: { modalVisible: modal },
  });

// * REDUCERS

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case ActionKind.SHOW:
      const form = action.payload!.modalVisible as FORM;
      return produce(state, (draft) => {
        draft.modalVisible = form;
      });

    case ActionKind.HIDE:
      return produce(state, (draft) => {
        draft.modalVisible = undefined;
      });
  }
  return state;
};

export const ModalManagerStateContext = createContext<IState>(defaultState);
export const ModalManagerDispatchContext = createContext<Dispatch<IAction>>(
  () => {},
);

export const ModalManagerProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <ModalManagerStateContext.Provider value={state}>
      <ModalManagerDispatchContext.Provider value={dispatch}>
        {children}
      </ModalManagerDispatchContext.Provider>
    </ModalManagerStateContext.Provider>
  );
};
