import { FC, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'context/user';
import Loader from 'components/loader';
import { getParams } from 'utils/browser';

const PublicRoute: FC<any> = ({
  component,
  path,
  exact = false,
  redirect,
  ...props
}) => {
  const { user, loading } = useContext(UserContext);
  const params = getParams();

  if (loading) {
    return <Loader />;
  }
  if (user?.id && !params?.redirect) {
    return <Redirect to="/showroom" />;
  }
  return <Route {...props} component={component} path={path} exact={exact} />;
};

export default PublicRoute;
