import { FC, useContext } from 'react';
import { Route } from 'react-router-dom';
import { OrganizationContext } from 'context/organization.context';
import { hasAccess } from 'utils/access/access.utils';
import NoMatch from './noMatch/component';

const CustomRoute: FC<any> = ({
  component,
  path,
  exact = false,
  admin = false,
  access,
  ...props
}) => {
  const { organization } = useContext(OrganizationContext)!;
  if (!hasAccess(access, organization?.accessAllowed)) {
    return <NoMatch />;
  }
  return <Route {...props} component={component} path={path} exact={exact} />;
};

export default CustomRoute;
